import React from "react";
import { Calculators } from ".";
import {
  Container,
  PageTitle,
  Section,
  SectionSubtitle,
  SectionTitle,
} from "../../components/calculators/calculators-layout";
import UnitConverter from "../../components/calculators/unit-converter";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const Cell = ({ children }) => {
  return (
    <div className="columns is-multiline">
      <div className="column is-6">{children}</div>
    </div>
  );
};

const CommonUnitConvertersPage = () => {
  return (
    <Layout>
      <Seo title="Common unit converters" />
      <Section>
        <Container>
          <PageTitle>Common unit converters</PageTitle>
          <p>You can click on each label to display the corresponding calculator </p>
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionTitle>Length</SectionTitle>
          <Cell>
            <UnitConverter
              title="Foots to inches"
              input={{ name: "foot", unit: "ft" }}
              output={{ name: "meter", unit: "m" }}
              coefficient={{ value: 0.3048 }}
            />
          </Cell>
          <Cell>
            <UnitConverter
              input={{ name: "inch", unit: "in" }}
              output={{ name: "meter", unit: "m" }}
              coefficient={{ value: 0.0254 }}
            />
          </Cell>
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionTitle>Area</SectionTitle>
          <Cell>
            <UnitConverter
              input={{
                unit: (
                  <>
                    ft<sup>2</sup>
                  </>
                ),
                defaultValue: 1,
              }}
              output={{
                unit: (
                  <>
                    m<sup>2</sup>
                  </>
                ),
              }}
              coefficient={{ value: 0.0929 }}
            />
          </Cell>
          <Cell>
            <UnitConverter
              input={{
                unit: (
                  <>
                    in<sup>2</sup>
                  </>
                ),
                defaultValue: 1,
              }}
              output={{
                unit: (
                  <>
                    m<sup>2</sup>
                  </>
                ),
              }}
              coefficient={{ value: 0.00065 }}
            />
          </Cell>
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionTitle>Volume</SectionTitle>
          <Cell>
            <UnitConverter
              input={{
                unit: "gal",
                name: "US gallon",
                defaultValue: 1,
              }}
              output={{
                unit: "L",
                name: 'liter'
              }}
              coefficient={{ value: 3.78541}}
            />
          </Cell>
          <Cell>
            <UnitConverter
              input={{
                unit: "gal",
                name: "US gallon",
                defaultValue: 1,
              }}
              output={{
                unit: (
                  <>
                    m<sup>3</sup>
                  </>
                ),
              }}
              coefficient={{ value: 0.00378 }}
            />
          </Cell>
          <Cell>
            <UnitConverter
              input={{
                unit: (
                  <>
                    ft<sup>3</sup>
                  </>
                ),
                defaultValue: 1,
              }}
              output={{
                unit: (
                  <>
                    m<sup>3</sup>
                  </>
                ),
              }}
              coefficient={{ value: 0.02831 }}
            />
          </Cell>
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionTitle>Rate</SectionTitle>
          <Cell>
            <UnitConverter
              input={{
                unit: "gpd",
                name: "gallon per day",
                defaultValue: 1,
              }}
              output={{
                unit: (
                  <>
                    10<sup>-5</sup> L s<sup>-1</sup>
                  </>
                ),
              }}
              coefficient={{ value: 4.381 }}
            />
          </Cell>
          <Cell>
            <UnitConverter
              input={{
                unit: "gpd",
                name: "gallon per day",
                defaultValue: 1,
              }}
              output={{
                unit: (
                  <>
                    10<sup>-3</sup> m<sup>3</sup> d<sup>-1</sup>
                  </>
                ),
              }}
              coefficient={{ value: 3.785 }}
            />
          </Cell>
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionTitle>Pressure</SectionTitle>
          <Cell>
            <UnitConverter
              input={{
                unit: "Pa",
                defaultValue: 1,
              }}
              output={{
                unit: "bar",
              }}
              coefficient={{
                value: Math.pow(10, -5),
                name: (
                  <>
                    10<sup>-5</sup>
                  </>
                ),
              }}
            />
          </Cell>
          <Cell>
            <UnitConverter
              input={{
                unit: "psi",
                defaultValue: 1,
              }}
              output={{
                unit: "bar",
              }}
              coefficient={{ value: 0.06894 }}
            />
          </Cell>
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionTitle>Flux</SectionTitle>
          <Cell>
            <UnitConverter
              input={{
                unit: "GFD",
                name: (
                  <>
                    gallon ft<sup>-2</sup> day<sup>-1</sup>
                  </>
                ),
                defaultValue: 1,
              }}
              output={{
                unit: (
                  <>
                    m s<sup>-1</sup>
                  </>
                ),
              }}
              coefficient={{
                value: 4.715 * Math.pow(10, -7),
                name: (
                  <>
                    4.715 10<sup>-7</sup>
                  </>
                ),
              }}
            />
          </Cell>
          <Cell>
            <UnitConverter
              input={{
                unit: "GFD",
                name: (
                  <>
                    gallon ft<sup>-2</sup> day<sup>-1</sup>
                  </>
                ),
                defaultValue: 1,
              }}
              output={{
                unit: (
                  <>
                    m day<sup>-1</sup>
                  </>
                ),
              }}
              coefficient={{
                value: 4.074 * Math.pow(10, -2),
                name: (
                  <>
                    4.074 10<sup>-2</sup>
                  </>
                ),
              }}
            />
          </Cell>
          <Cell>
            <UnitConverter
              input={{
                unit: "GFD",
                name: (
                  <>
                    gallon ft<sup>-2</sup> day<sup>-1</sup>
                  </>
                ),
                defaultValue: 1,
              }}
              output={{
                unit: (
                  <>
                    L m<sup>-2</sup> day<sup>-1</sup>
                  </>
                ),
              }}
              coefficient={{ value: 40.74 }}
            />
          </Cell>
          <Cell>
            <UnitConverter
              input={{
                unit: "GFD",
                name: (
                  <>
                    gallon ft<sup>-2</sup> day<sup>-1</sup>
                  </>
                ),
                defaultValue: 1,
              }}
              output={{
                unit: "LMH",
                name: (
                  <>
                    L m<sup>-2</sup> h<sup>-1</sup>
                  </>
                ),
              }}
              coefficient={{
                value: 1.7,
              }}
            />
          </Cell>
          <Cell>
            <UnitConverter
              input={{
                unit: (
                  <>
                    ft day<sup>-1</sup>
                  </>
                ),
                defaultValue: 1,
              }}
              output={{
                unit: (
                  <>
                    m day<sup>-1</sup>
                  </>
                ),
              }}
              coefficient={{ value: 0.3048 }}
            />
          </Cell>
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionTitle>Permeance</SectionTitle>
          <Cell>
            <UnitConverter
              input={{
                unit: (
                  <>
                    m Pa<sup>-1</sup> s<sup>-1</sup>
                  </>
                ),
                defaultValue: 1,
              }}
              output={{
                name:(
                  <>
                    L m<sup>-2</sup> h<sup>-1</sup> bar<sup>-1</sup>
                  </>
                ),
                unit:  "LMH/bar",
              }}
              coefficient={{ value: 3.6*Math.pow(10,11), name: <>3.60x10<sup>11</sup></> }}
            />
          </Cell>
        </Container>
      </Section>
      <hr />
      <Calculators />
    </Layout>
  );
};
export default CommonUnitConvertersPage;
