import React, { useCallback, useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import CalculatorsContext from "./calculators-context";

const Calculator = ({ fields, outputs, dependencies = [] }) => {
  const formRef = useRef();
  const { values, update } = useContext(CalculatorsContext);
  const inputs = fields;
  const otherCalculatorsDependencies = dependencies.map(
    (fieldName) => values[fieldName]
  );

  useEffect(() => {
    fields.forEach((field) => {
      if (field.defaultValue) {
        handleFieldUpdate(field, field.defaultValue, outputs);
      }
    });
  }, []);

  const handleFieldUpdate = useCallback((field, fieldValue, outputs) => {
    update(
      field && field.name && (fieldValue !== undefined || fieldValue !== null)
        ? { [field.name]: fieldValue }
        : {},
      outputs.map((output) => ({
        name: output.name,
        calculate: output.calculate,
      }))
    );
  }, []);

  useEffect(() => {
    handleFieldUpdate(undefined, undefined, outputs);
  }, otherCalculatorsDependencies);

  return (
    <form ref={formRef}>
      <ul className={fields.length > 1 ? "is-numbered-roman" : ""}>
        {inputs.map((field, index) => {
          const fieldId = "v" + index;
          return (
            <li key={index} className="mb-4">
              <label className="label" htmlFor={fieldId}>
                {field.label}{" "}
              </label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input
                    className="input is-small"
                    type="text"
                    pattern="[0-9]+([,\.][0-9]+)?"
                    inputMode="decimal"
                    id={fieldId}
                    placeholder="Enter value"
                    required
                    defaultValue={field.defaultValue}
                    value={
                      values[field.name] ||
                      (field.calculate &&
                        typeof field.calculate === "function" &&
                        field.calculate(values)) ||
                      ""
                    }
                    //onBlur={() => formRef.current.reportValidity()} // Disabled because of a bug who always refocuses the field in Safari
                    onChange={(e) => {
                      handleFieldUpdate(field, e.target.value.replace(',', '.'), outputs);
                    }}
                  />
                </div>
                <div className="control">
                  <div className="button is-static has-text-grey-dark is-small">
                    <b>{field.unit}</b>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
        <hr />
        {outputs.map((output) => {
          if (output.display !== undefined && output.display === false)
            return null;
          return (
            <li className="mb-4 output" key={output.name}>
              <label className="label" htmlFor={output.name}>
                {output.label}
              </label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input
                    className={[
                      "input",
                      !isNaN(values[output.name])
                        ? "has-background-success-light"
                        : undefined,
                    ].join(" ")}
                    id={output.name}
                    readOnly
                    placeholder="Please fill all the fields"
                    value={
                      !isNaN(values[output.name])
                        ? parseFloat(
                            // Hack to avoid number precision errors + remove trailing zeroes
                            parseFloat(values[output.name])
                              .toPrecision(12)
                              .toString()
                          )
                        : ""
                    }
                  />
                </div>
                <div className="control">
                  <div className="button is-static has-text-grey-dark">
                    <b>{output.unit}</b>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </form>
  );
};
export default Calculator;
