import React from "react";
import Calculator from "./calculator";
import { CalculatorsContextProvider } from "./calculators-context";
import { Container } from "./calculators-layout";

const UnitConverter = ({ input, output, coefficient }) => {
  /* const coefficientName = coefficient.name || coefficient.value + "";
  const inputName = input.name ? <>{input.name} ({input.unit})</> : input.unit
  const unit = coefficient.name ? <>({output.unit})</> : output.unit */
  const inputUnitLabel = input.name ? (
    <>
      {input.name} ({input.unit})
    </>
  ) : (
    <>{input.unit}</>
  );
  const coefficientLabel = coefficient.name ? (
    <>{coefficient.name}</>
  ) : (
    <>{coefficient.value}</>
  );
  const outputUnitLabel = output.name ? (
    <>
      {output.name} ({output.unit})
    </>
  ) : (
    <>{output.unit}</>
  );
  return (
    <details style={{ cursor: "pointer" }}>
      <summary>
        1 {inputUnitLabel} = {coefficientLabel} {outputUnitLabel}
        {/*  <>1 {inputName} = {coefficientName} {unit}</> */}
      </summary>
      <CalculatorsContextProvider>
        <Calculator
          fields={[
            {
              name: "input",
              label: <></>,
              unit: <>{input.unit}</>,
              defaultValue: 1,
            },
          ]}
          outputs={[
            {
              name: output.name,
              label: <></>,
              unit: <>{output.unit}</>,
              calculate: ({ input }) => coefficient.value * input,
            },
          ]}
        />
      </CalculatorsContextProvider>
    </details>
  );
};
export default UnitConverter;
