import { Link } from "gatsby";
import React from "react";
import {
  Container,
  Section,
  SectionTitle,
} from "../../components/calculators/calculators-layout";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

export const Calculators = () => (
    <Section>
        <Container>
          <SectionTitle>Calculators</SectionTitle>
          <div className="columns is-multiline is-mobile">
            <Link to='/calculators/membrane-performance-calculator' className="has-text-weight-bold is-underlined column is-3-tablet is-6-mobile">
              <div className="has-background-primary-light box" style={{height: '100%'}}>Membrane Performance Calculator</div>
            </Link>
            <Link to='/calculators/concentration-polarization-calculator' className="has-text-weight-bold is-underlined column is-3-tablet is-6-mobile">
              <div className="has-background-primary-light box" style={{height: '100%'}}>Concentration Polarization Calculator</div>
            </Link>
            <Link to='/calculators/osmotic-pressure-calculator' className="has-text-weight-bold is-underlined column is-3-tablet is-6-mobile">
              <div className="has-background-primary-light box" style={{height: '100%'}}>Osmotic Pressure Calculator</div>
            </Link>
            <Link to='/calculators/common-unit-converters' className="has-text-weight-bold is-underlined column is-3-tablet is-6-mobile">
              <div className="has-background-primary-light box" style={{height: '100%'}}>Common Unit Converters</div>
            </Link>
          </div>
        </Container>
      </Section>
)

const CalculatorsPage = () => {
  return (
    <Layout>
      <Seo />
      <Calculators />
    </Layout>
  );
};
export default CalculatorsPage;
