import React from "react";
import { MathJax } from "better-react-mathjax";

export const Section = ({ children }) => (
  <section className="section">{children}</section>
);
export const SubSection = ({ children }) => (
  <div className="columns is-centered my-5">
    <div className="column is-10">{children}</div>
  </div>
);
export const Centered = ({ children }) => (
  <div className="columns is-centered">
    <div className="column is-8">{children}</div>
  </div>
);
export const Container = ({ children }) => (
  <div className="container is-max-desktop">{children}</div>
);
export const PageTitle = ({ children }) => (
  <h1 className="title is-spaced mb-5">{children}</h1>
);
export const SectionTitle = ({ children }) => (
  <h2 className="title is-4 is-spaced">{children}</h2>
);
export const SectionSubtitle = ({ children }) => (
  <h2 className="title is-5 is-spaced">{children}</h2>
);
export const Equation = ({ math }) => (
  <p className="has-text-centered mb-3">
    {typeof window !== "undefined" && (
      <span className="box is-inline-block my-5">
        <MathJax>
          <span dangerouslySetInnerHTML={{ __html: math }} />
        </MathJax>
      </span>
    )}
  </p>
);
export const InlineEquation = ({ math }) =>
  typeof window !== "undefined" && (
    <MathJax style={{ display: "inline-block" }}>
      <span dangerouslySetInnerHTML={{ __html: math }} />
    </MathJax>
  );
export const Note = ({ children }) => (
  <div className="is-italic mb-3">Note: {children}</div>
);
export const Details = ({ children }) => (
  <details className="has-background-primary-light px-3 py-3">
    {children}
  </details>
);
export const Summary = ({ children }) => (
  <summary className="mb-3" style={{ cursor: "pointer" }}>
    {children}
  </summary>
);
